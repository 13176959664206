/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */

*:focus {
	outline: 0;
}

button {
	cursor: pointer;
}
