/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
    font-family: 'Apertura';
    src: url('../fonts/Apertura-Bold.woff2') format('woff2'),
        url('../fonts/Apertura-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* @font-face {
    font-family: 'Apertura Md';
    src: url('../fonts/Apertura-BlackObliq.woff2') format('woff2'),
        url('../fonts/Apertura-BlackObliq.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apertura Cn';
    src: url('../fonts/Apertura-Condensed.woff2') format('woff2'),
        url('../fonts/Apertura-Condensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apertura Cn';
    src: url('../fonts/Apertura-BoldCondensedObliq.woff2') format('woff2'),
        url('../fonts/Apertura-BoldCondensedObliq.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apertura Cn';
    src: url('../fonts/Apertura-CondensedObliq.woff2') format('woff2'),
        url('../fonts/Apertura-CondensedObliq.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'Apertura';
    src: url('../fonts/Apertura-Black.woff2') format('woff2'),
        url('../fonts/Apertura-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
} 

@font-face {
    font-family: 'Apertura';
    src: url('../fonts/Apertura-BoldObliq.woff2') format('woff2'),
        url('../fonts/Apertura-BoldObliq.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

/* @font-face {
    font-family: 'Apertura Cn';
    src: url('../fonts/Apertura-BoldCondensed.woff2') format('woff2'),
        url('../fonts/Apertura-BoldCondensed.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'Apertura';
    src: url('../fonts/Apertura-Regular.woff2') format('woff2'),
        url('../fonts/Apertura-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* @font-face {
    font-family: 'Apertura MdCn';
    src: url('../fonts/Apertura-MediumCondensed.woff2') format('woff2'),
        url('../fonts/Apertura-MediumCondensed.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apertura MdCn';
    src: url('../fonts/Apertura-MediumCondObliq.woff2') format('woff2'),
        url('../fonts/Apertura-MediumCondObliq.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'Apertura';
    src: url('../fonts/Apertura-Obliq.woff2') format('woff2'),
        url('../fonts/Apertura-Obliq.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Apertura';
    src: url('../fonts/Apertura-Medium.woff2') format('woff2'),
        url('../fonts/Apertura-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

