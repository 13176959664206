/* ------------------------------------------------------------ *\
	Form 
\* ------------------------------------------------------------ */

.form {
	.form__inner {
		padding-left: 300px;
	}

	.form__content {
		margin-bottom: 31px;
	}

	.form__row {
		position: relative; 
	}

	.form__row + .form__row {
		margin-top: 15px;
	}

	.form__label {
		margin-bottom: 0;
		flex: 1;
		max-width: 279px;
		color: $yellow;
		font-weight: 700; 
		font-size: 28px; 
		position: absolute; 
		top: 10px;
		right: 106%;  
		text-align: right;
		min-width: 277px;
		line-height: 1;
	}

	.form__actions {
		padding-top: 25px;
	}

	.form__btn {
		margin-bottom: 0;
		min-width: 200px;
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		border-radius: 0;
		border: none;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.form {
		.form__inner {
			padding-left: 270px;
		}

		.form__label {
			font-size: 24px; 
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.form {
		.form__inner {
			padding-left: 0;
		}

		.form__label {
			margin-bottom: 10px;
			font-size: 20px; 
			position: static; 
			text-align: left;
			min-width: auto;
		}
	}
}
