/* ------------------------------------------------------------ *\
	Contacts
\* ------------------------------------------------------------ */

.contacts {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&__text {
		width: 50%;
		padding-right: 40px;
	}

	&__title {
		margin-bottom: 35px;
	}

	&__phone {
		display: block;
		font-size: 47px;
		font-weight: 500;
		text-decoration: none;
		color: $white; 

		&:hover {
			color: $white;
			opacity: 1;
		}
	}

	&__subtitle {
		margin-bottom: 15px;
		font-size: 35px;
		font-weight: 500;
		line-height: 1.14;
	}

	&__info {
		margin-top: 65px;
	}

	&__entry {
		font-size: 25px;
		line-height: 1.2;
		font-weight: 500;
	}

	&__mail {
		display: block;
		font-size: 30px;
		font-weight: 500;
		text-decoration: none;
		color: $white;
		word-break: break-word;

		&:hover {
			color: $white;
			opacity: 1;
		} 
	}

	&__map {
		width: 50%;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.contacts {
		&__text {
			width: 100%;
			padding-right: 0;
		}

		&__map {
			width: 100%;
			margin-top: 20px;
		}

		&__title {
			margin-bottom: 20px;
		}

		&__phone,
		&__mail {
			font-size: 20px;
		}

		&__info {
			margin-top: 20px;
		}

		&__subtitle {
			font-size: 24px;
		}

		&__entry {
			font-size: 18px;
		}
	}
}
