/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	padding: 50px 0;

	&__inner {
		display: flex;
	}

	&__aside {
		display: flex;
		flex-wrap: wrap;
		flex: 1;
		padding-left: 180px;
		margin: 0 -30px;
	}

	&__col {
		width: 33.33%;
		padding: 0 30px;
	}

	&__full {
		width: 100%;
		margin-top: 50px;
		padding-left: 30px;

		ul {
			display: flex;
			padding: 0;
			margin: 0;
			list-style-type: none;
		}

		a {
			color: $white;
			font-size: 12px;
			letter-spacing: 0.05em; 
		}

		li + li {
			margin-left: 60px;
		}
	}

	&__title {
		margin-bottom: 20px;
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 0.05em; 
	}

	.socials {
		margin-top: 20px;
	}

	&__nav {
		ul {
			display: flex;
			flex-wrap: wrap;
			padding: 0;
			margin: 0 -10px -10px;
			list-style-type: none;
		}

		a {
			color: $white;
			font-size: 12px;
			letter-spacing: 0.05em; 
		}

		li {
			width: 50%;
			padding: 0 10px;
			margin-bottom: 10px;
		}
	}

	&__info {
		font-size: 12px;
		letter-spacing: 0.05em;
		line-height: 2.5;  
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.footer {
		&__aside {
			padding-left: 50px;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.footer {
		&__inner {
			display: block;
		}

		&__aside {
			padding-left: 0;
			margin-top: 20px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.footer {
		padding: 25px 0;

		&__full {
			margin-top: 20px;
			
			ul {
				display: block;
			}

			li + li {
				margin: 10px 0 0 0;
			}
		}

		&__aside {
			display: block;
		}

		&__title {
			margin-bottom: 10px;
		}

		&__col {
			width: 100%;

			& + & {
				margin-top: 20px;
			}
		}
	}
}
