/* ------------------------------------------------------------ *\
	Brand colors in Draftail menu & sitewide
\* ------------------------------------------------------------ */

yellow, .yellow {
    color: $yellow;
}

blue, .blue {
    color: $blue;
}

green, .green {
    color: $green;
}

pink, .pink {
    color: $pink;
}