/* ------------------------------------------------------------ *\
	Tile
\* ------------------------------------------------------------ */

.tile {
	display: flex;
	align-items: flex-start;

	.tile__image {
		padding-bottom: 40.6%; 
		flex: 1;
		width: 100%; 
		max-width: 475px;
		position: relative; 
	}

	.tile__image img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.tile__content {
		margin-top: -4px;
		padding-left: 26px;
		flex: 1;
		line-height: 1.26;
	}

	.tile__bar {
		margin-bottom: 24px;
		display: flex;
		justify-content: space-between;
	}

	.tile__bar-content {
		margin-right: 20px;
	}

	.tile__entry p {
		margin-bottom: 19px;
	}

	.tile__entry p:last-child {
		margin-bottom: 0;
	}

	.tile__actions {
		padding-top: 27px;
	}

	.tile__btn {
		width: 100%; 
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.tile {
		.tile__image {
			max-width: 350px;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.tile {
		.tile__image {
			max-width: 250px;
		}

		.tile__bar {
			display: block; 
		}

		.tile__bar-content {
			margin-bottom: 20px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.tile {
		display: block; 

		.tile__bar {
			margin-bottom: 30px;
		}

		.tile__image {
			margin-bottom: 30px;
			padding-bottom: 70%; 
			max-width: none;
		}

		.tile__content {
			padding-left: 0;
		}
	}
}