/* ------------------------------------------------------------ *\
	Mixins
\* ------------------------------------------------------------ */

@mixin reset-button {
	border: 0;
	background: transparent;
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;
}

@mixin position-iframe {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);
}

@mixin stretch-absolute {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
