/* ------------------------------------------------------------ *\
	Breadcrumbs
\* ------------------------------------------------------------ */

.breadcrumbs {
	font-size: 24px;
	color: #fff; 
	line-height: 1;

	ul {
		margin: -5px -20px -5px -5px;
		padding: 0; 
		display: flex;
		flex-wrap: wrap;
	}

	li {
		padding: 5px 20px 5px 5px;
		list-style-type: none;
		position: relative; 

		&:after {
			content: '>';
			position: absolute;
			top: 50%; 
			right: 0;  
			line-height: 1;
			transform: translateY(-50%);
		}
	}

	li:last-child {
		&:after {
			display: none; 
		}
	}

	a {
		color: inherit;
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.breadcrumbs {
		font-size: 18px; 

		li {
			padding-right: 15px;

			&:after {
				transform: translateY(-55%);
			}
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.breadcrumbs {
		font-size: 16px; 
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.breadcrumbs {
		font-size: 14px; 

		li {
			padding-right: 12px;
		}	
	}
}