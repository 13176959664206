/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: relative;
	padding: 60px 0 72px;

	&__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__aside {
		display: flex;
	}

	&__utilities {
		ul {
			display: flex;
			padding: 0;
			margin: 0;
			list-style-type: none;
		}

		li + li {
			margin-left: 24px;
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.header {
		.logo img {
			width: 150px; 
		}

		&__utilities {
			li + li {
				margin-left: 15px;
			}
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.header {
		padding: 30px 0;

		&__aside {
			display: none;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			z-index: 2;
			padding: 10px;
			background: $yellow;
		}
	}
}
