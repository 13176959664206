/* ------------------------------------------------------------ *\
	Cards
\* ------------------------------------------------------------ */

.cards {
	margin-right: -2px;
	padding-top: 8px;
	
	.cards__item + .cards__item {
		margin-top: 43px;
		padding-top: 44px;
		border-top: 2px solid $white;
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.cards {
		margin-right: 0;
		padding-top: 0;
	}
}