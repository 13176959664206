/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	margin: 100px 0;

	&--solid {
		padding: 80px 0;
		margin: 0;
		background: $white;

		.section__title {
			color: $black;
		}
	}

	&__entry {
		font-size: 20px;
		line-height: 1.2;
	}

	&__title {
		margin-bottom: 66px;
		text-align: center;
	}

	&__title small {
		margin-bottom: 14px;
		display: block; 
		font-size: 30px; 
		font-weight: 700; 
	}

	&__bar {
		margin-bottom: 48px;
	}

	&__head {
		margin-bottom: 78px;
		font-size: 24px; 
	}

	&__head--alt {
		margin-bottom: 64px;
	}

	&__head &__title {
		margin-bottom: 68px;
		text-align: left;
	}

	&__aside-content {
		margin-bottom: 127px;
	}

	&__content-foot,
	&__aside-foot {
		padding-left: 4px;
		font-size: 22px; 
		line-height: 1.32;
	}

	&__content-foot h4,
	&__aside-foot h4 {
		margin-bottom: 25px;
		color: $yellow;
		font-weight: 700; 
	}

	&__aside .section__aside-title {
		margin-bottom: 58px;
		font-size: 40px; 
		font-weight: 700; 
	}

	&__content.offset-left {
		padding-left: 68px;
	}

	&__content .section__actions {
		padding-top: 61px;
	}

	&__content-foot {
		padding-top: 50px;
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.section {
		&__content.offset-left {
			padding-left: 0;
		}

		&__content-foot,
		&__aside-foot {
			font-size: 20px; 
		}
	}	
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.section {
		margin: 50px 0;

		&__head {
			margin-bottom: 50px;
			font-size: 20px; 
		}

		&--solid {
			padding: 50px 0;
			margin: 0;
		}

		&__head &__title {
			margin-bottom: 50px;
		}

		&__aside .section__aside-title {
			font-size: 36px; 
		}

		&__aside-content {
			margin-bottom: 70px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.section {
		margin: 30px 0;

		&__head {
			font-size: 18px; 
		}

		&--solid {
			padding: 30px 0;
			margin: 0;
		}

		&__head &__title {
			margin-bottom: 30px;
		}

		&__aside .section__aside-title {
			margin-bottom: 30px;
			font-size: 24px; 
		}

		&__content-foot,
		&__aside-foot {
			font-size: 16px; 
		}
	}
}
