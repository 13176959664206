/* ------------------------------------------------------------ *\
	Box
\* ------------------------------------------------------------ */

.panels {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -17px -45px;

	.panel {
		width: 33.33%;
		padding: 0 17px;
		margin-bottom: 45px;
	}
}

.panel {
	&__inner {
		position: relative;
	}

	&__overlay {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		padding: 20px;
		background: $yellow;
		opacity: 0;
		visibility: hidden;
		transition: all .4s;

		h4 {
			color: $white;
		}
	}

	&__inner:hover {
		@media(min-width: 1025px){
			.panel__overlay {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__content {
		margin-top: 20px;
	}

	&__title {
		font-size: 25px;
		line-height: 1.2;
		color: $black;

		strong {
			font-weight: 900;
		}
	}

	&__image {
		&:before {
			content: '';
			display: block;
			padding-top: 100%;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.panels {
		.panel {
			width: 50%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.panels {
		.panel {
			width: 100%;

			&__title {
				font-size: 20px;
			}
		}
	}
}
