/* ------------------------------------------------------------ *\
	Quote
\* ------------------------------------------------------------ */

.quote {
	padding: 36px 30px 95px;
	background: $white;
	color: $black;
	text-align: center;

	blockquote {
		margin-bottom: 0;
		font-size: 23px;
		line-height: 1.17;

		p {
			margin-bottom: 5px;
		}

		cite {
			font-size: 15px;
			font-weight: 700;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.quote {
		padding: 20px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.quote {
		blockquote {
			font-size: 16px;

			cite {
				font-size: 12px;
			}
		}
	}
}

.boxes + .quote {
	margin-top: 20px;
}

/* ------------------------------------------------------------ *\
	Quote Alt
\* ------------------------------------------------------------ */

.quote-alt {
	max-width: 825px;
	margin: 0 auto;
	text-align: center;

	&__title {
		font-size: 60px;
	}

	&__text {
		margin-top: 20px;
		font-style: italic;
		font-size: 35px;
		line-height: 2;
	}

	&__cite {
		margin-top: 20px;
		font-size: 35px;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.quote-alt {
		&__title {
			font-size: 35px;
		}

		&__text,
		&__cite {
			font-size: 24px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.quote-alt {
		&__title {
			font-size: 24px;
		}

		&__text,
		&__cite {
			font-size: 18px;
		}
	}
}
