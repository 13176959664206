/* ------------------------------------------------------------ *\
	Radio
\* ------------------------------------------------------------ */

.radio {
	font-size: 20px;

	input {
		appearance: none;
		opacity: 0;
		position: absolute;  
	}

	label {
		padding-left: 50px;
		display: block;
		position: relative; 

		&:before {
			content: '';
			width: 32px;
			height: 32px;
			border: 3px solid currentColor;
			border-radius: 50%;
			position: absolute; 
			top: 0; 
			left: 0; 
		}

		&:after {
			content: '';
			display: block; 
			width: 12px;
			height: 12px;
			background-color: currentColor;
			position: absolute; 
			top: 10px;
			left: 10px; 
			border-radius: 50%;
			opacity: 0;
			transition: opacity $t-duration;
		}
	}

	input:checked + label:after {
		opacity: 1;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.radio {
		font-size: 18px;

		label {
			padding-left: 40px;

			&:before {
				width: 28px;
				height: 28px;
				position: absolute; 
				top: -2px; 
				left: 0; 
			}

			&:after {
				content: '';
				width: 10px;
				height: 10px;
				top: 7px;
				left: 9px; 
			}
		}
	}
}