/* ------------------------------------------------------------ *\
	List Services
\* ------------------------------------------------------------ */

.list-services {
	ul {
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
		padding: 0;
		margin: 0 -10px -10px;
	}

	li {
		width: 25%;
		padding: 0 10px;
		margin-bottom: 10px;
		font-size: 25px;
		line-height: 1.5;
		text-align: center;
	}

	strong {
		font-weight: 700;
		color: $yellow;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.list-services {
		li {
			width: 50%;
			font-size: 30px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.list-services {
		li {
			width: 100%;
			font-size: 24px;
		}
	}
}