/* ------------------------------------------------------------ *\
	Widgets
\* ------------------------------------------------------------ */

.widgets {
	margin: 0;
	padding: 0;

	> li {
		list-style-type: none; 
	}

	> li + li {
		margin-top: 89px;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.widgets {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 30px;

		> li + li {
			margin-top: 0;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.widgets {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(205px, auto));
		gap: 30px;
	}
}