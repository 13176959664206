/* ------------------------------------------------------------ *\
	Map
\* ------------------------------------------------------------ */

.gm-style {
	font: inherit;
}

.map {
	position: relative;

	&__iframe,
	&__iframe-static {
		height: 937px;
	}

	&__iframe-static iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.map {
		&__iframe,
		&__iframe-static {
			height: 443px;
		}
	}
}
