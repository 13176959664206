/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

.list {
	margin: 0;
	padding: 0;
	font-size: 20px; 
	line-height: 1.2;

	li {
		list-style-type: none; 
	}

	li + li {
		margin-top: 6px;
	}

	strong {
		font-weight: 700; 
		color: $yellow;
	}
}

/*  List Alt  */

.list--alt {
	font-size: 28px; 

	li + li {
		margin-top: 5px;
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.list {
		font-size: 18px; 
	}

	.list--alt {
		font-size: 22px; 
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.list {
		font-size: 18px; 
	}

	.list--alt {
		font-size: 18px; 
	}
}