/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	white-space: normal;

	&:focus,
	&.focus {
		box-shadow: none;
	}
}

.btn-primary {
	@include button-variant(red, green, blue, pink, yellow);

	color: black;

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		border-color: coral;

		&:focus {
			box-shadow: none;
		}
	}

	&:focus,
	&.focus {
		box-shadow: none;
	}
}

.btn-outline-primary {
	@include button-outline-variant(red, green, blue, pink);

	color: yellow;

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		&:focus {
			box-shadow: none;
		}
	}

	&:focus,
	&.focus {
		box-shadow: none;
	}
}
