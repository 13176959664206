/* ------------------------------------------------------------ *\
	Read More Text Container
\* ------------------------------------------------------------ */

[data-read-more] {
	overflow: hidden;
	transition: height .5s;
}

/* ------------------------------------------------------------ *\
	Read More Button
\* ------------------------------------------------------------ */

.read-less-btn,
.read-more-btn {
	@include reset-button;
	display: inline-block;
	padding: 0;
	margin-top: 10px;
	font-size: 25px;
	line-height: 1;
	color: $white;
}
