/* ------------------------------------------------------------ *\
	Slider Images
\* ------------------------------------------------------------ */

.slider-images {
	position: relative;

	&__paging {
		position: absolute;
		bottom: 25px !important;
		z-index: 2; 
		text-align: center;

		.swiper-pagination-bullet {
			width: 25px;
			height: 25px;
			background: $white;
			margin: 0 18px !important;
		}
	}

	&__image {
		&:before {
			content: '';
			display: block;
			padding-top: 100%;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.slider-images {
		&__paging {
			.swiper-pagination-bullet {
				width: 15px;
				height: 15px;
				margin: 0 10px !important;
			}
		}
	}
}
