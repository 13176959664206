/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */

.checkbox {
	input {
		-webkit-appearance: none;
		appearance: none;
 		position: absolute; 
 		opacity: 0;
 		z-index: -1;
	}

	label {
		min-height: 50px;
		padding-left: 50px;
		display: inline-flex;
		align-items: center;
		position: relative;

		&:before {
			content: '';
			display: inline-block;
			width: 34px;
			height: 34px;
			border: 2px solid currentColor;
			position: absolute; 
			top: 7px; 
			left: 0; 
		}

		&:after {
			content: '';
			display: block;
			width: 10px;
			height: 22px;
			border:  solid currentColor;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			position: absolute; 
			top: 10px; 
			left: 12px;
			opacity: 0; 
			transition: opacity $t-duration;
		}
	}

	input:checked + label:after {
		opacity: 1;
	}
}