/* ------------------------------------------------------------ *\
	Radios
\* ------------------------------------------------------------ */

.radios {

	ul {
		padding: 0;
		margin: 0;
	}

	li {
		list-style-type: none;
	}

	li + li {
		margin-top: 27px;
	}
}