/* ------------------------------------------------------------ *\
	Text Media
\* ------------------------------------------------------------ */

.text-media {
	display: flex;
	flex-wrap: wrap;

	&__text {
		width: 50%;
		padding-right: 90px;
		margin-bottom: 20px;
	}

	&__media {
		width: 50%;
	}

	&__image:after {
		content: '';
		display: block; 
		padding-top: 100%;
	}

	&__title {
		font-size: 60px;
		line-height: 1.17;
		font-weight: 700;
	}

	&__entry {
		margin-top: 77px;
		font-size: 35px;
		line-height: 2;
	}

	&.reversed {
		flex-direction: row-reverse;

		.text-media__text {
			padding-right: 0;
			padding-left: 90px;
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.text-media {
		&__title {
			font-size: 45px;
		}

		&__entry {
			font-size: 24px;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.text-media {
		&__title {
			font-size: 35px;
		}

		&__entry {
			font-size: 20px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.text-media {
		&__text,
		&__media {
			width: 100%;
		}

		&__title {
			font-size: 24px;
		}

		&__entry {
			margin-top: 30px;
			font-size: 18px;
		}

		&__text {
			padding-right: 0;
			margin-bottom: 20px;
		}

		&.reversed {
			flex-direction: row-reverse;

			.text-media__text {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
}
