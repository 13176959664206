/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	background: $black;
	color: $white;
	font-family: $primary-font;
	font-size: 15px;
}

ul:last-child,
ol:last-child,
p:last-child {
	margin-bottom: 0;
}

img {
	max-width: 100%;
	height: auto;
}

h1,
.h1 {
	font-size: 55px;
	font-weight: 500;
}

h2,
.h2 {
	font-size: 55px;
	font-weight: 700;
}

h4,
.h4 {
	font-size: 27px;
	font-weight: 900;
	line-height: 1;
}

h5,
.h5 {
	font-size: 20px;
	font-weight: 700;
	line-height: 1.2;
}

.yellow,
a.yellow:hover {
	color: #FFC04A;
}

.blue,
a.blue:hover {
	color: #00C4E9;
}

.green,
a.green:hover {
	color: #71CDA1;
}

.pink,
a.pink:hover {
	color: #F59BC9;
}

/* === Tablet === */
@include media-breakpoint-down(md) {

	h1,
	.h1 {
		font-size: 35px;
	}

	h2,
	.h2 {
		font-size: 35px;
	}

	h4,
	.h4 {
		font-size: 24px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {

	h1,
	.h1 {
		font-size: 24px;
	}

	h2,
	.h2 {
		font-size: 24px;
	}

	h4,
	.h4 {
		font-size: 18px;
	}

	h5,
	.h5 {
		font-size: 16px;
	}
}

.bg--pink {
	background-color: $pink;
}

.bg--yellow {
	background-color: $yellow;
}

.bg--blue {
	background-color: $blue;
}

.bg--green {
	background-color: $green;
}