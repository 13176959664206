/* ------------------------------------------------------------ *\
	Box
\* ------------------------------------------------------------ */

.boxes {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -20px;

	.box {
		width: 25%;
		margin-bottom: 20px;
	}
}

.box {
	& > a {
		display: block;
	}

	&__content {
		margin-top: 35px;
		padding-right: 30px;
	}

	&__title {
		margin-bottom: 26px;
	}

	&__image {
		&:before {
			content: '';
			display: block;
			padding-top: 100%;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.boxes {
		.box {
			width: 50%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.boxes {
		.box {
			width: 100%;

			&__content {
				margin-top: 30px;
			}

			&__title {
				margin-bottom: 15px;
			}
		}
	}
}
