/* ------------------------------------------------------------ *\
	Members
\* ------------------------------------------------------------ */

.members {
	display: flex;
	flex-wrap: wrap;

	.member {
		width: 33.33%;
	}
}

.member {
	position: relative;

	&__overlay {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		padding: 20px;
		opacity: 0;
		visibility: hidden;
		transition: all .4s;

		h4 {
			color: $white;
		}

		&--center {
			align-items: center;
		}

		&--bottom {
			align-items: flex-end;
		}

		&--yellow {
			background: $yellow;
		}

		&--blue {
			background: $blue;
		}

		&--green {
			background: $green;
		}

		&--pink {
			background: $pink;
		}
	}

	&__inner {
		display: block;
	}

	&__inner:hover {
		text-decoration: none;

		@media(min-width: 1025px){
			.member__overlay {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__image {
		&:after {
			content: '';
			display: block;
			padding-top: 100%;
		}
	}

	&__position {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 20px;
		margin-bottom: 0;
		color: $white;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.members {
		.member {
			width: 50%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.members {
		.member {
			width: 100%;
		}
	}
}
