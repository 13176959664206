/* ------------------------------------------------------------ *\
	Timeline
\* ------------------------------------------------------------ */

.timelines {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	margin: 0 -15px -40px;

	.timeline {
		width: 50%;
		padding: 0 15px;
		margin-bottom: 40px;
	}
}

.timeline {
	&__inner {
		position: relative;
	}

	&__entry {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 30px;
		font-size: 40px;
		line-height: 1.13;
		font-weight: 700;
		color: $white;
	}

	&__year {
		font-size: 65px;
		line-height: 1;
	}

	&--empty .timeline__inner,
	&__image {
		&:before {
			content: '';
			display: block;
			padding-top: 100%;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.timelines {
		.timeline {
			width: 100%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.timeline {
		&__entry {
			padding: 15px;
			font-size: 24px;
		}

		&__year {
			font-size: 45px;
		}
	}
}