/* ------------------------------------------------------------ *\
	Card Primary
\* ------------------------------------------------------------ */

.card-primary {
	display: grid;
	gap: 15px;
	grid-template-columns: 118px auto 1fr;
	line-height: 1.26;

	.card__image {
		margin-bottom: 0;
		position: relative;
		padding-bottom: 100%;  
		max-width: 118px;
	}

	.card__image img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.card__content {
		margin-right: 9px; 
		gap: 38px;
		display: grid;
		grid-template-columns: auto 1fr;
	}

	.card__actions {
		padding-top: 3px;
	}

	.card__btn {
		display: flex; 
		color: $white;
	}

	.card__btn + .card__btn {
		margin-top: 13px;
	}
}

/*  Card Primary Yellow  */

.card-primary--yellow {
	.card__list strong {
		color: $yellow;
	}

	.card__btn {
		background-color: $yellow;
	}
}

/*  Card Primary Pink  */

.card-primary--pink {
	.card__list strong {
		color: $pink;
	}

	.card__btn {
		background-color: $pink;
	}
}

/*  Card Primary Pink  */

.card-primary--blue {
	.card__list strong {
		color: $blue-2;
	}

	.card__btn {
		background-color: $blue-2;
	}
}

/*  Card Primary Pink  */

.card-primary--green {
	.card__list strong {
		color: $green-2;
	}

	.card__btn {
		background-color: $green-2;
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.card-primary {
		.card__content {
			margin-right: 0; 
			gap: 20px
		}
	}
}


/* === Tablet === */
@include media-breakpoint-down(sm) {
	.card-primary {
		display: block; 

		.card__image {
			margin-bottom: 25px;
			padding-bottom: 56%;
			max-width: 100%; 
		}

		.card__content {
			margin-bottom: 25px;
			gap: 30px;
			grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
		}
	}
}