/* ------------------------------------------------------------ *\
	Link
\* ------------------------------------------------------------ */

.link {
	font-weight: 500;
	text-decoration: none;

	&:hover {
		color: currentColor;
	}
}
