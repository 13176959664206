/* ------------------------------------------------------------ *\
	Text Image
\* ------------------------------------------------------------ */

.text-image {
	display: flex;
	flex-wrap: wrap;

	& + & {
		margin-top: 56px;
	}

	&.center {
		align-items: center;
	}

	&.reversed {
		flex-direction: row-reverse;

		.text-image__text {
			padding-right: 0;
			padding-left: 50px;
		}
	}

	&__entry {
		font-size: 24px;
		line-height: 1.42;
	}

	&__subtitle {
		font-size: 40px;
		line-height: 1.18;

		strong {
			font-weight: 700;
		} 

		a {
			font-size: 50px;
		}
	}

	&__title {
		font-size: 50px;
		font-weight: 500;
		line-height: 1.14;
	}

	&__text {
		flex: 1;
		padding-top: 50px;
		padding-right: 50px; 
	}

	&__image {
		width: 570px;
		flex: 1;

		&:before {
			content: '';
			display: block;
			padding-top: 100%;
		}

		&:only-child:before {
			padding-top: 48.72%
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.text-image {
		&__title {
			font-size: 35px;
		}

		&__subtitle {
			font-size: 30px;

			a {
				font-size: 35px;
			}
		}

		&__entry {
			font-size: 18px;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.text-image {
		&.reversed {
			.text-image__text {
				padding: 0;
			}
		}

		&__text {
			flex: 0 0 100%;
			padding: 0;
			margin-top: 20px;
			flex-shrink: 0;

			br {
				display: none;
			}
		}

		&__image {
			flex: 0 0 100%;
			order: -1;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.text-image {
		& + & {
			margin-top: 20px;
		}
		
		&__title {
			font-size: 30px;
		}

		&__subtitle {
			font-size: 24px;

			a {
				font-size: 24px;
			}
		}
	}
}
