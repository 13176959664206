/* ------------------------------------------------------------ *\
	News
\* ------------------------------------------------------------ */

.news {
	display: flex;
	flex-wrap: wrap;
	margin: 40px -17px -33px;

	.news-item {
		width: 33.33%;
		padding: 0 17px;
		margin-bottom: 33px;
	}
}

.news-item {
	&__content {
		position: relative;
		padding: 35px 70px 32px 30px;
		background: $white;
	}

	&__icon {
		position: absolute;
		bottom: 17px;
		right: 17px;
	}

	&__title {
		font-size: 30px;
		font-weight: 700;
		line-height: 1.5; 
	}

	&__entry {
		font-size: 20px;
		line-height: 2.25;
	}

	&__image {
		&:before {
			content: '';
			display: block;
			padding-top: 100%;
		}
	}

	&__image--small {
		&:before {
			padding-top: 56.83%;
		}
	}

	&__image--large {
		&:before {
			padding-top: 128.14%;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.news {
		.news-item {
			width: 50%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.news {
		.news-item {
			width: 100%;
		}
	}
}
