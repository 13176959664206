/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widget {
	.widget__title {
		margin-bottom: 36px;
		font-weight: 700; 
		letter-spacing: .01em;
		font-size: 28px; 
	}
}

/* === mobile === */
@include media-breakpoint-down(sm) {
	.widget {
		.widget__title {
			font-size: 22px; 
		}
	}
}