/* ------------------------------------------------------------ *\
	Slider Full
\* ------------------------------------------------------------ */

.slider-full {
	position: relative;

	&__paging {
		position: absolute;
		bottom: 25px !important;
		z-index: 2; 
		text-align: center;

		.swiper-pagination-bullet {
			width: 25px;
			height: 25px;
			background: $white;
			margin: 0 18px !important;
		}
	}

	.content {
		display: flex;
		align-items: center;
		height: 652px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		&__inner {
			max-width: 465px;
		}

		&__title {
			font-size: 60px;
		}

		&__entry {
			font-size: 35px;
			line-height: 2;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.slider-full {
		.content {
			padding: 100px 0;
			height: auto;

			&__title {
				font-size: 40px;
			}

			&__entry {
				font-size: 21px;
			}
		}

		&__paging {
			.swiper-pagination-bullet {
				width: 15px;
				height: 15px;
				margin: 0 10px !important;
			}
		}
	}
}

