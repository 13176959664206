/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$primary-font: 'Apertura', sans-serif;

/* ------------------------------------------------------------ *\
	Bootstrap Variables
\* ------------------------------------------------------------ */

/*  Fonts  */
$font-family-sans-serif: $primary-font;

$line-height-base: 1.6;

/*  Paragraphs  */
$paragraph-margin-bottom: 1.6em;

/*  Container  */
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px
);

/*  Grid Breakpoints  */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1281px
) !default;

/* Colors */
$yellow: #FFC04A;
$blue: #00C4E9;
$blue-2: #1dc1dd;
$green: #71CDA1;
$green-2: #5ba883;
$pink: #F59BC9;
$white: #ffffff;
$light: #f5faf5;

/*  Transition Duration  */

$t-duration: .4s;