/* ------------------------------------------------------------ *\
	Field
\* ------------------------------------------------------------ */

.field {
	padding: 11px 20px;
	display: block; 
	max-width: 100%;
	width: 100%; 
	background-color: $white;
	border: none;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	background-color: $light;
}

/*  Field Textarea  */

.field--textarea {
	height: 173px;
}

/*  Field File  */

.field-file {
	-webkit-appearance: none;
	appearance: none; 
	position: absolute; 
	z-index: -1;
	opacity: 0;	
	resize: none;
}