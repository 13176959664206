/* ------------------------------------------------------------ *\
	BUtton Secondary
\* ------------------------------------------------------------ */

.btn-alt {
	padding: 14px 20px 12px;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	white-space: normal;
	background-color: $white;
	font-size: 15px;
	line-height: 1.2;
	font-weight: 700;  
	color: #000;
	text-decoration: none; 
	min-width: 143px;
	transition: opacity $t-duration;
	cursor: pointer;

	@media(hover: hover) {
		&:hover {
			opacity: .7;
			text-decoration: none; 
		}
	}
}

.btn-alt--yellow {
	background-color: $yellow;
	color: $white;
}