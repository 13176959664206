/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	ul {
		display: flex;
		font-size: 20px;
		font-weight: 500;
		padding: 0;
		margin: 0;
		list-style-type: none;
	}

	a {
		color: $white;
		line-height: 1;
	}

	li + li {
		margin-left: 45px;
	}

	& + .header__utilities {
		margin-left: 42px;
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.nav {
		li + li {
			margin-left: 20px;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.nav {
		ul {
			display: block;
		}

		li + li {
			margin: 10px 0 0 0;
		}

		& + .header__utilities {
			margin: 10px 0 0 0;
		}
	}
}
