/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	ul {
		display: flex;
		align-items: center;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	li + li {
		margin-left: 15px;
	}

	a {
		transition: opacity $t-duration;

		@media(hover: hover) {
			&:hover {
				opacity: .7;
			}
		}
	}
}

/*  Socials Alt  */

.socials--alt {
	h5 {
		margin-bottom: 0; 
		font-size: 19px;
		color: $yellow; 
	}

	a {
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: center; 
		width: 39px;
		height: 39px;
		border-radius: 50%;
		border: 2px solid #fff;
	}

	img {
		width: 100%; 
		max-width: 18px;
		max-height: 21px;
		object-fit: contain;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.socials--alt {
		a {
			width: 36px;
			height: 36px;
		}

		img {
			max-width: 14px;
			max-height: 17px; 
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.socials {
		li + li {
			margin-left: 10px;
		}
	}

	.socials--alt {
		h5 {
			font-size: 16px; 
		}
	}
}