/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
	text-align: center;

	&__title {
		margin-top: 126px;
	}

	&__entry {
		margin-top: 138px;
		font-size: 47px;
		line-height: 1.17;
		font-weight: 500;
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.intro {
		&__entry {
			br {
				display: none;
			}
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.intro {
		&__title {
			margin-top: 30px;
		}

		&__entry {
			margin-top: 30px;
			font-size: 24px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.intro {
		&__logo {
			img {
				width: 250px;
			}
		}

		&__entry {
			font-size: 21px;
		}
	}
}
