/* ------------------------------------------------------------ *\
	Paging
\* ------------------------------------------------------------ */

.paging {
	display: flex;
	color: #000;
	font-size: 22px;	
	font-weight: 700;  
	line-height: 1.2;

	ul {
		border: solid currentColor;
		border-width: 0 2px;
		margin: 0;
		padding: 0;
		display: flex;
	}

	li {
		list-style-type: none;
	}

	li + li {
		position: relative; 
		border-left: 2px solid currentColor;
	}

	a {
		padding: 11px 18px 7px 17px;
		display: block; 
		text-decoration: none; 
		background-color: $light;
		color: inherit;
		transition: opacity $t-duration;

		@media(hover: hover) {
			&:hover {
				opacity: .7;
			}
		}
	}

	.paging__btn {
		padding: 9px 11px 7px 11px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.paging {
		font-size: 16px; 
		justify-content: center;

		a {
			padding: 9px 15px 5px;
		}

		.paging__btn {
			padding: 8px 9px 6px;
		}	
	}
}